<template>
  <!--讲师详情-->
  <div class="common_inner">
    <div class="wxq_lecturer_information">
      <div class="wxq_detail">
        <img
          :src="detailData.avatar ? detailData.avatar : defaultAvatar"
          alt=""
        />
        <div class="wxq_detail_datum">
          <p>{{ detailData.teaName }}</p>
          <p>{{ detailData.teaTitle }}</p>
        </div>
      </div>
      <p class="wxq_detail_introduction">
        {{ detailData.teaDesc }}
      </p>
    </div>
    <ul class="wxq_tab">
      <li
        :class="{ 'active color_blue': activeName == 'live' }"
        @click="changeTab('live')"
      >
        参与直播({{ liveTotalCount }})
      </li>
      <li
        :class="{ 'active color_blue': activeName == 'dibble' }"
        @click="changeTab('dibble')"
      >
        参与点播({{ dibbleTotalCount }})
      </li>
    </ul>
    <live-com
      v-show="activeName == 'live'"
      key="liveComp"
      :type="'liveType'"
      :livedata="livedata"
    />
    <dibble-com
      v-show="activeName == 'dibble'"
      key="dibbleComp"
      :type="'dibbleType'"
      :dibbledata="dibbledata"
    />
  </div>
</template>

<script>
import { EventBus } from "@assets/js/eventBus";
import API from "@apis/backStageEndAPI/lecturerAPI";
import liveCom from "@comps/studentsComponent/liveCom";
import dibbleCom from "@comps/studentsComponent/liveCom";
import CommonUtils from "@utils";
export default {
  name: "teacherDetail",
  components: {
    liveCom,
    dibbleCom,
  },
  data() {
    return {
      defaultAvatar: require("@/assets/img/backStageImg/user-icon.png"),
      detailData: {},
      activeName: "live",
      form: {
        courseType: 1, //1直播，2录播
        page: 1, //页码
        pageSize: 10, //分页大小
        teaId: "", //讲师id
      },
      livedata: [],
      dibbledata: [],
      liveTotalCount: 0,
      dibbleTotalCount: 0,
    };
  },
  mounted() {
    EventBus.$emit("curIndex", 3);
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id);
      this.form.teaId = this.$route.query.id;
      this.getTableList();
      this.getDibbleList();
    }
  },
  methods: {
    getTableList() {
      //获取直播列表
      this.form.courseType = 1;
      API.getClientTeacherCourse(CommonUtils.parseToParams(this.form)).then(
        (res) => {
          this.livedata = res.dataList;
          this.liveTotalCount = res.rowCount;
        }
      );
    },
    getDibbleList() {
      //获取录播列表
      this.form.courseType = 2;
      API.getClientTeacherCourse(CommonUtils.parseToParams(this.form)).then(
        (res) => {
          this.dibbledata = res.dataList;
          this.dibbleTotalCount = res.rowCount;
        }
      );
    },
    changeTab(tabName) {
      //点击切换登录方式
      this.activeName = tabName;
    },
    getDetail(id) {
      API.getClientTeacherDetail(id).then((res) => {
        if (res && !res.status) {
          this.detailData = res;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.wxq_lecturer_information {
  background: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 24px;
  padding: 30px;
}
.wxq_detail {
  width: 100%;
  height: 150px;
  background: #f4f9fd;
  border-radius: 24px;
  padding: 18px;
  margin-bottom: 20px;
  display: flex;
  img {
    width: 108px;
    height: 108px;
    border-radius: 50%;
  }
  .wxq_detail_datum {
    margin-left: 15px;
    p:first-of-type {
      font-size: 20px;
      font-weight: 500;
      color: #0a1629;
    }
    p:last-of-type {
      font-size: 14px;
      color: #7d8592;
      line-height: 26px;
    }
  }
}
.wxq_tab {
  margin: 42px 0 16px 0;
  width: 20%;
  li {
    float: left;
    padding-bottom: 10px;
    width: 50%;
    color: #91929e;
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    cursor: pointer;
  }
  .active {
    position: relative;
  }
  .active::after {
    position: absolute;
    bottom: 6px;
    left: 50%;
    z-index: 1;
    width: 32px;
    height: 4px;
    background-color: var(--colorBlue);
    content: "";
    transform: translate(-50%, -50%);
  }
}
.wxq_tab::after {
  display: block;
  clear: both;
  content: "";
}
</style>
